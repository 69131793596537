import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Offset } from 'components/Containers';
import { Link } from 'gatsby';

export default class CardNews extends React.Component {
  render () {
    const propClasses = this.props.className;
    const classes = classNames(
      `ptb-20px`,
      {
        [`bg-${this.props.bgColor}`]: true
      },
      ...propClasses
    );
    return (
      <header className={classes}>
        <Offset>
          <div className={`mb-0 pb-0 fs-14px`}>
            <Link
              to={`/support`}
              itemProp="url"
              title={`Support Center`}
              style={{ color: 'white' }}
            >
              Support Center
            </Link>
            <span className="alpha-60" style={{ margin: '0 4px' }}>-</span>
            <Link
              to={`/support/resources`}
              itemProp="url"
              title={`Downloads & Resources`}
              style={{ color: 'white' }}
            >
              Downloads & Resources
            </Link>
          </div>
        </Offset>
      </header>
    )
  }
}
CardNews.propTypes = {
  title: PropTypes.string.isRequired,
  bgColor: PropTypes.string,
  className: PropTypes.string
};
CardNews.defaultProps = {
  bgColor: 'indigo-500',
  className: ''
};
