// Helper functions for filtering
export const defaultMatcher = (filterText, node) => {
  return node.name.toLowerCase().indexOf(filterText.toLowerCase()) !== -1;
};

export const findNode = (node, filter, matcher) => {
  return (
    matcher(filter, node) || // i match
    (node.children && // or i have descendants and one of them match
      node.children.length &&
      !!node.children.find(child => findNode(child, filter, matcher)))
  );
};

export const filterTree = (node, filter, matcher = defaultMatcher) => {
  // If im an exact match then all my children get to stay
  if (matcher(filter, node) || !node.children) {
    return node;
  }
  // If not then only keep the ones that match or have matching descendants
  const filtered = node.children
    .filter(child => findNode(child, filter, matcher))
    .map(child => filterTree(child, filter, matcher));
  return Object.assign({}, node, { children: filtered });
};

export const expandFilteredNodes = (node, filter, matcher = defaultMatcher) => {
  let { children } = node;
  const exceptions = [
    'Products',
    'MT HOOD',
    'TAHOMA',
    'CRESCENT',
    'openGear',
    'MAZAMA',
    'Other Resources'
  ];
  let location;
  if (typeof window !== 'undefined') {
    location = window.location;
  }
  const { productLine, series } = node;
  // console.log("EXPAND >>> node", node.name );
  // console.log("> EXPAND >>> node", node );
  // console.log("> EXPAND >>> node.type", node.type );
  // console.log("> EXPAND >>> node.current", node.current );
  // console.log("> EXPAND >>> children", children );
  // if(productLine) console.log("> EXPAND >>> node productLine", productLine );
  // if(series) console.log("> EXPAND >>> node series", series );

  const activeProductLine = location && location.pathname.toLowerCase().startsWith(`/support/resources/${node.name.toLowerCase()}`);
  // console.log(">>> EXPAND >>> activeProductLine", activeProductLine);
  // console.log(">>> EXPAND >>> location.pathname.toLowerCase()", location.pathname.toLowerCase());
  // console.log(">>> EXPAND >>> startsWith(`/support/resources/${node.name.toLowerCase()", `/support/resources/${node.name.toLowerCase()}`);
  if (activeProductLine) {
    // console.log(">>> EXPAND >>> LINE ACTIVE", productLine);
  }

  // const nodeActive = location && location.pathname.startsWith(`/support/resources/${productLine.slug}/${series.slug}`);
  // const nodeToggled = location && location.pathname.startsWith(`/support/resources/${productLine.slug}/${series.slug}`);

  if (!children || children.length === 0 || (!exceptions.includes(node.name) && !node.current)) {
    return Object.assign({}, node, { toggled: false });
  }
  const childrenWithMatches = node.children.filter(child =>
    findNode(child, filter, matcher));
  const shouldExpand = childrenWithMatches.length > 0;
  // If im going to expand, go through all the matches and see if their children need to expand
  if (shouldExpand) {
    children = childrenWithMatches.map((child) => {
      // console.log("EXPAND CHILD > child", child);
      return expandFilteredNodes(child, filter, matcher);
    });
  }
  return Object.assign({}, node, {
    children,
    toggled: shouldExpand
  });
};
