import React from 'react';
import { graphql } from 'gatsby';
import Layout from 'components/Layout';
import get from 'lodash/get';
import logger from 'utils/logger';
import Img from 'components/Elements/Img';
import numeral from 'numeral';
import _ from 'lodash';
import { countries, lookup, languages } from 'country-data';
import ReactPlayer from 'react-player';
import ResourcesHeader from 'components/Headers/ResourcesHeader';

import { Container, Row, Col, Table } from 'reactstrap';
import { Fluid } from 'components/Containers';
import SEO from 'components/SEO/SEO';
import LoadingView from 'components/Loaders/LoadingView';
import MenuTree from 'components/Search/MenuTree';
import treeDataSchema from 'components/Search/ResourcesTreeData';

class ProductSeriesSingle extends React.Component {
  render () {
    logger.template(__filename, '>', 'render()');
    const data = get(this, 'props.data');
    logger.gql(data);
    const productLinesData = data.allContentfulProductLine.edges;
    if (
      !data ||
      !data.contentfulProductSeries ||
      !data.allContentfulProductLine
    ) {
      return <LoadingView />;
    }
    const productSeries = data.contentfulProductSeries;
    const productLine = productSeries.productLine && productSeries.productLine;

    const { products } = productSeries;

    logger.data(products);
    const downloadsLine = data.contentfulProductLine.fields.uniqueDownloads;
    logger.verbose(
      'Series downloadsLine data.contentfulProductLine.fields.uniqueDownloads ',
      downloadsLine
    );
    const downloadsResources = productSeries.downloads;
    const { downloadsReference } = productSeries;
    const downloadsProducts = [];
    if (products) {
      products.forEach((product) => {
        if (product.downloads) {
          if (Array.isArray(product.downloads)) {
            product.downloads.forEach((download) => {
              downloadsProducts.push(download);
            });
          }
        }
      });
    }
    let downloadsConcat = [];

    let downloads = [];
    if (typeof window !== 'undefined') {
      if (Array.isArray(downloadsResources)) {
        logger.debug(`downloadsResources is array`, downloadsResources);
        downloadsConcat = _.concat(downloadsConcat, downloadsResources);
      }
      if (Array.isArray(downloadsReference)) {
        logger.debug(`downloadsReference is array`, downloadsReference);
        downloadsConcat = _.concat(downloadsConcat, downloadsReference);
      }
      if (Array.isArray(downloadsProducts)) {
        logger.debug(`downloadsProducts is array`, downloadsProducts);
        downloadsConcat = _.concat(downloadsConcat, downloadsProducts);
      }
      if (Array.isArray(downloadsLine)) {
        logger.debug(`downloadsLine is array`, downloadsLine);
        downloadsConcat = _.concat(downloadsConcat, downloadsLine);
      }
      logger.debug(`downloadsConcat`, downloadsConcat);
      downloads = _.uniqBy([...downloadsConcat], `id`);
    }
    logger.debug(`downloadsConcat uniqBy`, downloadsConcat);

    const downloadTypesBase = [
      // `catalog`,
      // `datasheet`,
      // `manual`,
      // `support`,
      // `software`,

      'catalog',
      'datasheet',
      'manual',
      'support',
      'software',
      'document',
      'warranty',
      'case-study',
      'recommended-hardware',
      'media',
      'drawing'
    ];

    const downloadTypesLabels = {
      catalog: 'Catalogs',
      datasheet: 'Datasheets',
      manual: 'Manuals',
      support: 'Support Documents',
      software: 'Software',
      document: 'Other Documents',
      warranty: 'Warranty',
      'case-study': 'Case Studies',
      'recommended-hardware': 'Recommended Hardware',
      media: 'Product Images & Videos',
      drawing: 'Drawings'
    };
    const downloadTypesDynamic = [];
    if (downloads.length > 0) {
      downloads.forEach((download) => {
        if (download) {
          logger.debug('download', download);
          if (!downloadTypesDynamic.includes(download.type)) {
            downloadTypesDynamic.push(download.type);
          }
        }
      });
    }
    const downloadTypes = _.intersection(
      downloadTypesBase,
      downloadTypesDynamic
    );
    logger.verbose('Series downloads', downloads);
    logger.verbose('Series downloadTypes', downloadTypes);
    logger.verbose('Series productSeries.downloads', productSeries.downloads);
    const k = 0;

    const entryTitle = `${productLine && productLine.name} ${productSeries &&
      productSeries.name}`;

    const treeData = treeDataSchema(productLinesData);

    logger.templateEnd();

    return (
      <Layout>
        <Fluid>
          <SEO
            title={`Resources for ${entryTitle}`}
            description={
              productSeries.summary && productSeries.summary.summary
            }
            image={
              productSeries.thumbnail && productSeries.thumbnail.fixed.src
            }
            pathname={`/support/resources/${
              productLine ? productLine.slug : `l`
            }/${productSeries.slug}`}
            type={`product`}
          />
          <ResourcesHeader />
          <section className={`articles bt-1`}>
            <Container fluid>
              <Row>
                <Col
                  md={{ size: 12, order: 2 }}
                  lg={{ size: 9, order: 2 }}
                  className={`pt-20em prl-30em`}
                >
                  <article>
                    <header>
                      <Row className={`align-items-center`}>
                        <Col md={6} lg={4} className={`pb-10em`}>
                          <div className="thumbnail mb-15em">
                            <Img
                              title={`${productSeries.productLine} ${
                                productSeries.name
                              }`}
                              alt={`${productSeries.productLine} ${
                                productSeries.name
                              }`}
                              sizes={productSeries.thumbnail.fluid}
                              backgroundColor={`#fafafa`}
                              style={{
                                width: `100%`,
                                maxWidth: '320px'
                              }}
                              imgStyle={{
                                top: `50%`,
                                transform: `translate3D(-50%,-50%,0)`,
                                left: `50%`
                              }}
                              objFit={`contain`}
                              fadeIn
                            />
                          </div>
                        </Col>
                        <Col md={6} lg={8} className={`pb-10em`}>
                          <h1
                            className={`fs-30em ls--30em pt-5em mb-0 pos-relative lh-12`}
                            style={{ zIndex: `1` }}
                          >
                            <span className={`fw-800`}>
                              {productLine && productLine.name}
                            </span>{' '}
                            <span className={`fw-400`}>
                              {productSeries.name}
                            </span>
                          </h1>
                          <p className={`fs-20em ff-sans fw-300 mb-1em`}>
                            {productSeries.tagline}
                          </p>
                          <p className={`lh-16 fs-095em alpha-70 mb-15em`}>
                            {productSeries && productSeries.summary && productSeries.summary.summary}
                          </p>
                          {products && products.length > 0 && (
                            <ul className="inline-block pb-0 models fs-08em uls-none prl-0 mrl-0 lh-14">
                              <li>
                                <span>Models: </span>
                              </li>
                              {products.map((product, i) => {
                                return (
                                  product &&
                                  product.name &&
                                  !product.eol && (
                                    <li
                                      key={`product-${product.id}`}
                                      className={`model ${
                                        product.name
                                      } mb-0 ${products.length > 0 &&
                                        `inline-block mr-0 text-left`}`}
                                      style={{
                                        padding: `2px 4px 2px 0`
                                      }}
                                    >
                                      <div
                                        className={`bg-gray-warm-200`}
                                        style={{
                                          display: `block`,
                                          padding: `3px 8px`,
                                          borderRadius: '4px'
                                        }}
                                      >
                                        <b>{product.name}</b>
                                      </div>
                                    </li>
                                  )
                                );
                              })}
                            </ul>
                          )}
                        </Col>
                      </Row>
                    </header>
                    <section className={`pb-30em`}>
                      <Container fluid>
                        <Row>
                          <Col>
                            <Table responsive className={`fs-09em`}>
                              <thead>
                                <tr>
                                  <td className="fs-08em pt-20em alpha-70" width="45%"><strong>Resource</strong></td>
                                  <td className="text-center fs-08em pt-20em alpha-70" width="12%"><strong>Language</strong></td>
                                  <td className="text-center fs-08em pt-20em alpha-70" width="12%"><strong>Type</strong></td>
                                  <td className="text-center fs-08em pt-20em alpha-70" width="12%"><strong>Date</strong></td>
                                  <td className="text-left fs-08em pt-20em alpha-70" width="12%"><strong>File</strong></td>
                                  <td width="5%" />
                                </tr>
                              </thead>
                            </Table>
                            {downloadTypes &&
                              downloadTypes.map((type) => {
                                const label = downloadTypesLabels[type] || <span style={{ textTransform: `capitalize` }}>{type.replace('-', ' ')}</span>;
                                return (
                                  <Table
                                    key={`table-dType-${type}`}
                                    responsive
                                    className={`fs-09em rt-table`}
                                  >
                                    <thead>
                                      <tr>
                                        <th width="45%">{label}</th>
                                        <th width="12%" />
                                        <th width="12%" />
                                        <th width="12%" />
                                        <th width="12%" />
                                        <th width="5%" />
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {downloads.length > 0 &&
                                        downloads.sort((a, b) => {
                                          if (a && b) {
                                            if (a.sort < b.sort) { return -1; }
                                            if (a.sort > b.sort) { return 1; }
                                          }
                                          return 0;
                                        })
                                          .sort((a, b) => {
                                            if (a && b) {
                                              if (a.type < b.type) { return -1; }
                                              if (a.type > b.type) { return 1; }
                                            }
                                            return 0;
                                          })
                                          .map((download) => {
                                            if (download) {
                                              if (download.resource) {
                                                const downloadType = download.type;
                                                let icon;
                                                if (downloadType === `datasheet`) { icon = `File-Chart`; } else if (downloadType === `manual` || downloadType === `support`) { icon = `Book`; } else if (downloadType === `catalog`) { icon = `Address-Book2`; } else { icon = `Download-fromCloud`; }
                                                let extension = download.resource.file.contentType.split(`/`).pop();
                                                if (extension === `x-dosexec`) { extension = `Executable`; }
                                                if (extension === `octet-stream`) { extension = `Executable`; }
                                                const urlSplit = download.resource.file.url.split('/');
                                                const prettyURL = urlSplit[urlSplit.length - 1];
                                                let name = download.name;
                                                let matches;
                                                let lang;
                                                let language;
                                                let flag;
                                                if (download.name) {
                                                  matches = download.name.includes('(') && download.name.split('(');
                                                  lang = matches && matches[1].split(')')[0];
                                                  if (matches) { name = matches[0]; }
                                                }
                                                if (lang) {
                                                  language = lookup.languages({ name: lang })[0];
                                                  if (language) {
                                                    flag =
                                                        lookup.countries({ languages: language.alpha3 })[0] &&
                                                        lookup.countries({ languages: language.alpha3 })[0].alpha2.toLowerCase();
                                                  }
                                                }
                                                // const country = lookup.countries({ alpha2: country })[0] }
                                                let description;
                                                if (download.resource.description) {
                                                  description = download.resource.description;
                                                }

                                                logger.debug('DOWNLOAD', download);
                                                const fileContentType = download.resource.file.contentType;
                                                const fileContentTypeExtension = fileContentType.split(`/`).pop();

                                                return download.resource.file && downloadType === type ? (
                                                  <tr key={`download-${download.id}`} className={`mb-10em`}>
                                                    <th style={{ verticalAlign: 'middle' }} width="45%">
                                                      { fileContentType.includes('image') || fileContentType.includes('video') ? (
                                                        <div>
                                                          <div className={`block`}>
                                                            <div className={`block float-left`} style={{ width: `15%`, textAlign: `center` }}>
                                                              <i className={`icon icon-${icon} fs-18em pos-relative top-6px fw-500 ml-1em mr-8em`} />
                                                            </div>
                                                            <div className={`block float-left`} style={{ width: `80%` }}>
                                                              {
                                                                fileContentType.includes('image') && download.resource && download.resource.fluid && (
                                                                  <div style={{ padding: '0.25em 0 1em' }}>
                                                                    <a href={download.resource.file.url} target="_blank" rel="noopener noreferrer">
                                                                      <Img
                                                                        title={name}
                                                                        alt={name}
                                                                        fluid={download.resource.fluid}
                                                                        style={
                                                                          {
                                                                            height: `100%`,
                                                                            width: `100%`
                                                                          }
                                                                        }
                                                                        fadeIn
                                                                      />
                                                                    </a>
                                                                  </div>
                                                                )
                                                              }
                                                              {
                                                                fileContentType.includes('video') && download.resource && download.resource.file && (
                                                                  <div
                                                                    style={{
                                                                      position: 'relative',
                                                                      height: '0',
                                                                      margin: '0.25em 0 1em',
                                                                      paddingTop: '56.25%'
                                                                    }}
                                                                  >
                                                                    <div
                                                                      style={{
                                                                        position: 'absolute',
                                                                        top: '0',
                                                                        right: '0',
                                                                        bottom: '0',
                                                                        left: '0',
                                                                        border: '1px solid rgba(0,0,0.0.8)'
                                                                      }}
                                                                    >
                                                                      <ReactPlayer
                                                                        url={download.resource.file.url}
                                                                        width={`100%`}
                                                                        height={`100%`}
                                                                        controls
                                                                        light={'/img/videoPlaceholder.jpg'}
                                                                      />
                                                                    </div>
                                                                  </div>
                                                                )
                                                              }
                                                              <span className={`fs-10em fw-500`}>
                                                                {name}
                                                              </span>
                                                              <br />
                                                              <span className={`fs-08em color-gray-cold-400 tt-uppercase`}>
                                                                {type} <span>|</span> {fileContentTypeExtension} <span>|</span> {numeral(download.resource.file.details.size).format('0.0 b')}
                                                              </span>
                                                              {description && [
                                                                <br />,
                                                                <div
                                                                  className={`fs-095em color-gray-cold-600`} style={{
                                                                    paddingTop: `4px`,
                                                                    fontWeight: '500'
                                                                  }}
                                                                >
                                                                  <p className={`tt-uppercase color-gray-cold-400`} style={{ fontSize: '10px', margin: '4px 0' }}>
                                                                      Description:
                                                                  </p>
                                                                  <p className="alpha-90" style={{ fontWeight: '400' }}>{description}</p>
                                                                </div>
                                                              ]}
                                                            </div>
                                                          </div>
                                                        </div>
                                                      ) : (
                                                        <a href={`/downloads/files/${prettyURL}`} target={`_blank`} alt={name} className={`display-block color-primary-600`}>
                                                          <div className={`block`}>
                                                            <div className={`block float-left`} style={{ width: `15%`, textAlign: `center` }}>
                                                              <i className={`icon icon-${icon} fs-18em pos-relative top-6px fw-500 ml-1em mr-8em`} />
                                                            </div>
                                                            <div className={`block float-left`} style={{ width: `80%` }}>
                                                              <span className={`fs-10em fw-500`}>
                                                                {name}
                                                              </span>
                                                              <br />
                                                              <span className={`fs-08em color-gray-cold-400 tt-uppercase`}>
                                                                {type} <span>|</span> {fileContentTypeExtension} <span>|</span> {numeral(download.resource.file.details.size).format('0.0 b')}
                                                              </span>
                                                              {description && [
                                                                <br />,
                                                                <div
                                                                  className={`fs-095em color-gray-cold-600`} style={{
                                                                    paddingTop: `4px`,
                                                                    fontWeight: '500'
                                                                  }}
                                                                >
                                                                  <p className={`tt-uppercase color-gray-cold-400`} style={{ fontSize: '10px', margin: '4px 0' }}>
                                                                      Description:
                                                                  </p>
                                                                  <p className="alpha-90" style={{ fontWeight: '400' }}>{description}</p>
                                                                </div>
                                                              ]}
                                                            </div>
                                                          </div>
                                                        </a>
                                                      )}
                                                    </th>
                                                    <td width="12%" style={{ verticalAlign: 'middle' }} className="text-center">
                                                      <b>
                                                        <i
                                                          className={`flag-icon flag-icon-${flag || 'gb'}`}
                                                          style={{
                                                            top: `-1px`,
                                                            display: `inline-block`,
                                                            marginRight: `5px`,
                                                            fontSize: `12px`,
                                                            border: '1px solid #ccc'
                                                          }}
                                                        />
                                                      </b>
                                                      <span className={`alpha-80 fs-08em`}>{language ? language.name : 'English'}</span>
                                                    </td>
                                                    <td width="12%" style={{ verticalAlign: 'middle' }} className="text-center">
                                                      <span className={`alpha-80 fs-08em`}>{download.type}</span>
                                                      <br />
                                                    </td>
                                                    <td width="12%" style={{ verticalAlign: 'middle' }} className="text-center">
                                                      <div className="text-center fs-08em">
                                                        <span className={`alpha-80`}>{download.updatedAt}</span>
                                                      </div>
                                                    </td>
                                                    <td width="12%" style={{ verticalAlign: 'middle' }} className="text-left">
                                                      <div className="text-left alpha-80 fs-08em">
                                                        <span>{extension.toLowerCase() === 'executable' ? 'Application' : <span className="tt-uppercase">{extension}</span>}</span>
                                                        <span
                                                          style={{
                                                            top: `-1px`,
                                                            display: `inline-block`,
                                                            padding: `0 4px`
                                                          }}
                                                        >
                                                          {' '}
                                                            |{' '}
                                                        </span>
                                                        {numeral(download.resource.file.details.size).format('0.0 b')}
                                                      </div>
                                                    </td>
                                                    <td width="5%" style={{ verticalAlign: 'middle' }} className="text-left">
                                                      <a href={`/downloads/files/${prettyURL}`} target={`_blank`} alt={`Download ${download.resource.file.fileName}`} className={`display-block color-gray-cold-500 color-gray-cold-800-hover`}>
                                                        <i className={`icon icon-Download fs-13em fw-800`} />
                                                      </a>
                                                    </td>
                                                  </tr>
                                                ) : (``);
                                              }
                                            }
                                            return ``;
                                          })}
                                    </tbody>
                                  </Table>
                                );
                              })}
                          </Col>
                        </Row>
                      </Container>
                    </section>
                  </article>
                </Col>
                <Col
                  lg={3}
                  xl={3}
                  className={`bg-gray-cold-100 sidebar prl-0 d-none d-lg-block`}
                  style={{ borderRight: `1px solid #f1f0f7` }}
                >
                  <aside className={`sidebar`}>
                    <div>
                      <MenuTree defaultData={treeData} />
                    </div>
                  </aside>
                </Col>
              </Row>
            </Container>
          </section>
        </Fluid>
      </Layout>
    );
  }
}

export const query = graphql`
  query($slug: String!, $productLineSlug: String) {
    site {
      siteMetadata {
        title
        titleSeparator
      }
    }
    contentfulProductSeries(slug: { eq: $slug }) {
      id
      ...ProductSeries
      ...ProductSeriesSingle
    }
    contentfulProductLine(slug: { eq: $productLineSlug }) {
      name
      fields {
        uniqueDownloads {
          id
          name
          type
          resource {
            ...DownloadFile
          }
          related {
            __typename
          }
        }
      }
    }
    allContentfulProductLine(sort: { fields: [sort], order: DESC }) {
      edges {
        node {
          id
          ...ProductLineBase
          product_group {
            ...ProductGroupBase
            categories {
              ...Category
            }
            series {
              name
              eol
              productLine {
                name
                slug
              }
              ...ProductSeriesBase
              products {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

export default ProductSeriesSingle;
