import React from 'react';
import { Link } from 'gatsby';
import logger from 'utils/logger';

export function treeDataSchema (productLinesData) {
  let location;
  if (typeof window !== 'undefined') {
    location = window.location;
  }

  logger.verbose('location', location);

  const treeProductLines = [];
  if (Array.isArray(productLinesData)) {
    productLinesData.forEach((edge, li) => {
      const productLine = edge.node;
      // const product_group = [];
      if (productLine.product_group) {
        productLine.product_group.forEach((group, gi) => {
          // Object.assign(productLine.product_group[gi].children, group.series)
          // productLine.product_group[gi].toggled = false;
          const clearName = group.name;
          productLine.product_group[gi].type = 'group';
          productLine.product_group[gi].decorators = {
            Header: (props) => {
              return (
                <div
                  style={{
                    backgroundColor: 'transparent',
                    paddingBottom: '20px',
                    fontSize: '14px',
                    fontWeight: '600',
                    display: 'inline-block',
                    color: props.node.toggled
                      ? '#0081de'
                      : '#6d6e71'
                  }}
                >
                  {clearName.replace(`${productLine.name} `, '')}
                </div>
              );
            }
          };
          if (!clearName.includes(productLine.name)) {
            productLine.product_group[gi].name = `${
              productLine.name
            } ${group.name}`;
          }
          const groupChildren = [];
          if (group.series) {
            group.series.forEach((series, gsi) => {
              const seriesName = series.name;
              const productNames = [];
              if (series.products) {
                series.products.forEach((product) => {
                  productNames.push(product.name);
                });
              }
              if (
                !group.series[gsi].name.includes(productLine.name)
              ) {
                group.series[gsi].name = `${productLine.name} ${
                  group.name
                } ${series.name} ${productNames.toString()}`;
              }
              // logger.verbose('group.series', group.series)
              const seriesPrettyName = seriesName
                .replace(`${productLine.name} `, '')
                .replace(group.name, '')
                .replace(productNames.toString(), '');
              const active = location && location.pathname.startsWith(`/support/resources/${productLine.slug}/${series.slug}`);
              if (active) {
                logger.verbose('EXPAND ACTIVE productLine.product_group[gi]', productLine.product_group[gi]);
                productLine.product_group[gi].active = true;
                productLine.product_group[gi].current = true;
                productLine.product_group[gi].toggled = true;
                // productLine[li].active = true;
                // productLine[li].toggled = true;
              }
              groupChildren.push({
                ...series,
                type: 'series',
                active,
                toggled: active,
                decorators: {
                  Header: (props) => {
                    return (
                      <div
                        style={{
                          color: props.node.toggled
                            ? '#495057'
                            : '#6d6e71'
                        }}
                        className={`resourceSbLink ${active ? 'active' : ''}`}
                      >
                        <Link
                          to={`/support/resources/${
                            series.productLine && series.productLine.slug
                          }/${series.slug}`}
                          itemProp="url"
                          title={`${seriesPrettyName} Resources`}
                        >
                          {seriesPrettyName}
                          <div>
                            <div className="tagsM pt-4px">
                              <div
                                className="tt-uppercase pb-4px color-gray-cold-500"
                                style={{
                                  fontSize: '11px',
                                  opacity: '0.6'
                                }}
                              >
                                Models:
                              </div>
                              <ul className="p-0 m-0">
                                {props.node && props.node.products && props.node.products.map(product => (
                                  <li
                                    key={`menu-product-${
                                      product.id
                                    }`}
                                    style={{
                                      color: '#939598',
                                      display: 'inline-block',
                                      paddingRight: '14px',
                                      fontSize: '12px',
                                      letterSpacing: '0.05em'
                                    }}
                                  >
                                    <span
                                      style={{
                                        display: 'inline-block',
                                        paddingRight: '8px',
                                        opacity: '0.7'
                                      }}
                                    >
                                        •
                                    </span>
                                    {product.name}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        </Link>
                      </div>
                    );
                  }
                }
              });
            });
          }
          let groupActive = false;
          productLine.product_group[gi].children = groupChildren;
          if (groupChildren.some(e => e.toggled || e.active)) {
            groupActive = true;
          }
          productLine.product_group[gi].active = groupActive;
          productLine.product_group[gi].toggled = groupActive;
        });
      }
      treeProductLines.push({
        name: productLine.name,
        active: location && location.pathname.startsWith(`/support/resources/${productLine.slug}`),
        toggled: location && location.pathname.startsWith(`/support/resources/${productLine.slug}`),
        // toggled: true,
        decorators: {
          Header: (props) => {
            return (
              <div
                style={{
                  fontSize: '16px',
                  fontWeight: '600',
                  marginBottom: '12px',
                  display: 'inline-block',
                  color: props.node.toggled
                    ? '#0081de'
                    : '#495057'
                }}
              >
                {productLine.name}
              </div>
            );
          }
        },
        id: productLine.id,
        children: productLine.product_group
      });
    });
  }
  return {
    name: 'Products',
    id: 1,
    toggled: true,
    children: treeProductLines
  };
}

export default treeDataSchema;
