import React from 'react';
import { Treebeard } from 'react-treebeard';
import { navigate, Link } from 'gatsby';
import qs from 'qs';
import _ from 'lodash';
import TextField from '@material-ui/core/TextField';
import * as filters from '../../utils/filter';

const treeStyle = {
  tree: {
    base: {
      listStyle: 'none',
      backgroundColor: 'transparent !important',
      margin: 0,
      padding: 0,
      color: '#9DA5AB',
      fontFamily: 'Roboto, arial,sans-serif',
      fontSize: '14px'
    },
    node: {
      base: { backgroundColor: 'transparent', position: 'relative' },
      link: {
        backgroundColor: 'transparent !important',
        cursor: 'pointer',
        position: 'relative',
        padding: '0px 20px',
        display: 'block'
      },
      activeLink: {
        background: 'transparent'
      },
      toggle: {
        base: {
          backgroundColor: 'transparent !important',
          position: 'relative',
          display: 'inline-block',
          verticalAlign: 'top',
          marginLeft: '-2px',
          height: '24px',
          width: '24px'
        },
        wrapper: {
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate3D(-50%, -50%, 0)',
          height: '24px'
        },
        height: 8,
        width: 8,
        arrow: {
          display: 'inline-block',
          fill: '#9DA5AB',
          strokeWidth: 0
        }
      },
      header: {
        base: {
          backgroundColor: 'transparent',
          display: 'inline-block',
          verticalAlign: 'top',
          color: '#9DA5AB'
        },
        connector: {
          width: '2px',
          height: '24px',
          borderLeft: 'solid 2px black',
          borderBottom: 'solid 2px black',
          position: 'absolute',
          top: '0px',
          left: '-21px'
        },
        title: {
          lineHeight: '24px',
          verticalAlign: 'middle'
        }
      },
      subtree: {
        listStyle: 'none',
        paddingLeft: '19px'
      },
      loading: {
        color: '#E2C089'
      }
    }
  }
};

class MenuTree extends React.Component {
  constructor (props) {
    super(props);
    // console.log('MenuTree props', props)
    this.state = { data: props.defaultData, defaultData: props.defaultData };
    this.onToggle = this.onToggle.bind(this);
    this.onSelect = this.onSelect.bind(this);
    let queryString = '';
    let pathname = '';
    if (typeof window !== 'undefined') {
      queryString = qs.parse(window.location.search.substring(1));
      pathname = window.location.pathname;
    }
    this.updateQuery = (evt) => {
      let text = evt.target ? evt.target.value : '';
      if (evt.target) {
        if (evt.target.value) {
          navigate(`${pathname}?q=${evt.target.value}`);
        } else {
          navigate(`${pathname}`);
        }
      }
      if (!text && queryString.q) {
        text = queryString.q;
      }
      this.setState((s) => {
        return {
          ...s,
          query: text
        };
      });
    };
  }

  // componentDidMount() {
  //   this.setState(() => ({ data: this.props.defaultData }))
  // }

  onToggle (node, toggled) {
    const { cursor, data } = this.state;

    if (cursor) {
      this.setState(() => ({ cursor, active: false }));
    }

    node.active = true;
    if (node.children) {
      node.toggled = toggled;
    }

    this.setState(() => ({ cursor: node, data: Object.assign({}, data) }));
  }

  onSelect (node) {
    const { cursor, data } = this.state;

    if (cursor) {
      this.setState(() => ({ cursor, active: false }));
      if (!includes(cursor.children, node)) {
        cursor.toggled = false;
        cursor.selected = false;
      }
    }

    node.selected = true;

    this.setState(() => ({ cursor: node, data: Object.assign({}, data) }));
  }

  onFilterMouseUp ({ target: { value } }) {
    const filter = value.trim();
    const { defaultData } = this.state;
    console.log('filter', filter);
    if (!filter) {
      return this.setState(() => ({ data: defaultData }));
    }
    let filtered = filters.filterTree(defaultData, filter);
    filtered = filters.expandFilteredNodes(filtered, filter);
    this.setState(() => ({ data: filtered }));
  }

  render () {
    const { data, query } = this.state;

    return (
      <div>
        <div>
          <div className="input-group p-20px">
            <span className="input-group-addon">
              <i className="fa fa-search" />
            </span>
            <TextField
              id="name"
              className="form-control"
              label="Search"
              value={query}
              onChange={this.updateQuery}
              className={`mui mui-search mui-search-md`}
              onKeyUp={this.onFilterMouseUp.bind(this)}
              type="text"
            />
          </div>
        </div>
        <div className="ptb-20px treeBeard">
          {data ? (
            <Treebeard
              data={data}
              style={treeStyle}
              onToggle={this.onToggle}
              onSelect={this.onSelect}
            />
          ) : (
            <div>Loading</div>
          )}
        </div>
        <div className="pb-30em">
          <div style={{ ...treeStyle.tree.base }}>
            <Link to={`/resources/brand-assets`} style={{ ...treeStyle.tree.node.link }}>
              <span style={{ padding: '6px' }}>
                <svg height="8" width="8"><polygon points="0,0 0,8 8,4" class="css-1y2vwsp"></polygon></svg>
              </span>
              <strong>Brand Assets</strong>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default MenuTree;
